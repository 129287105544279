import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/images/shipaid-logo.png';

type Props = {
  backgroundImage: string;
  children: React.ReactNode;
};

function AuthPage({ backgroundImage, children }: Props) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-5 min-h-screen">
      <div
        style={{ backgroundImage: `url(${backgroundImage})` }}
        className="hidden md:flex  col-span-2 bg-cover bg-center  justify-center"
      >
        <Link to="/">
          <img
            className="max-w-[180px] mt-[80px]"
            src={Logo}
            alt="Shipaid logo"
          />
        </Link>
      </div>

      <div className="overflow-auto p-2 cols-span-5 md:col-span-3 mx-auto relative grid place-content-center">
        <div className="flex items-center justify-center flex-col">
          {children}

          <div className="mt-2 terms-text text-sm text-center  text-[#36343499] bottom-4 w-full">
            By joining, you are agreeing to our{' '}
            <a
              href="https://www.shipaid.com/terms-of-service"
              target="_blank"
              className="login-page-link text-black font-semibold"
              rel="noreferrer"
            >
              Terms
            </a>{' '}
            and{' '}
            <a
              href="https://shipaid.com/privacy-policy"
              target="_blank"
              className="login-page-link text-black font-semibold"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthPage;
