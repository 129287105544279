import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';

const ImageUploader = ({
  label,
  image,
  setImage,
}: {
  label?: string;
  image: string;
  setImage: (url: string) => void;
}) => {
  const [isUploading, setIsUploading] = useState(false);

  const upload = useCallback(
    async (files: File[]) => {
      const uploadURL = process.env.REACT_APP_IMAGE_UPLOAD_URL;
      const uploadPreset = process.env.REACT_APP_UPLOAD_PRESET;

      try {
        setIsUploading(true);
        await Promise.all(
          files.map(async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', uploadPreset as string);
            setIsUploading(true);

            const response = await fetch(uploadURL as string, {
              method: 'POST',
              body: formData,
            });

            const responseData = await response.json();
            const { secure_url: url } = responseData;
            return Promise.resolve(url);
          }),
        )
          .then((urls) => {
            setImage(urls[0]);
            setIsUploading(false);
          })
          .finally(() => setIsUploading(false));
      } catch (error) {
      } finally {
        setIsUploading(false);
      }
    },
    [setImage, setIsUploading],
  );

  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      upload([file]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
  });

  return (
    <div className="flex flex-col items-center">
      {label && <label className="mb-2 text-gray-700">{label}</label>}
      <div
        {...getRootProps()}
        className="w-64 h-40 border-2 rounded-sm border-dashed border-gray-400 flex flex-col justify-center items-center cursor-pointer hover:border-blue-500 transition-all"
      >
        <input {...getInputProps()} />
        {image ? (
          <div className="relative w-32 h-32 mt-2 rounded-lg overflow-hidden">
            <img
              src={image}
              alt="Preview"
              className="w-full h-full object-cover"
            />
          </div>
        ) : (
          <p className="text-gray-500 text-center m-10">
            Click or Drag & Drop an image here
          </p>
        )}
        {isUploading ? (
          <p className="text-sm my-2">Uploading, please wait...</p>
        ) : null}
      </div>
    </div>
  );
};

export default ImageUploader;
