import React, { useState } from 'react';
import {
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from '@heroicons/react/outline';

interface SectionPanelProps {
  title: string;
  children?: React.ReactNode;
}

export const FieldSection = ({
  left,
  right,
}: {
  left: React.ReactElement;
  right: React.ReactElement;
}) => {
  return (
    <div className="my-2 flex flex-row justify-between items-top shadow-sm py-2">
      <div>{left}</div>
      <div>{right}</div>
    </div>
  );
};

const SectionPanel = ({ title, children }: SectionPanelProps) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className="border border-gray-300 rounded-lg mb-4 bg-white shadow-sm">
      <div
        className="flex justify-between items-center p-4 bg-gray-100 cursor-pointer rounded-t-lg"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-semibold">{title}</h3>
        {isOpen ? (
          <ChevronDoubleUpIcon
            style={{ width: '20px' }}
            className="text-gray-500"
          />
        ) : (
          <ChevronDoubleDownIcon
            style={{ width: '20px' }}
            className="text-gray-500"
          />
        )}
      </div>
      {isOpen && <div className="p-4">{children}</div>}
    </div>
  );
};

export default SectionPanel;
