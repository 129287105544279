import React from 'react';

interface INumberInputProps {
  label?: string;
  value: string;
  step?: string;
  min?: number;
  max?: number;
  setValue: (val: string | number) => void;
}

const NumberInput = ({
  label,
  value,
  setValue,
  max = 100,
  min = 0,
  step = '1',
}: INumberInputProps) => {
  return (
    <div className="border rounded-sm w-[100px]">
      <label>
        <input
          type="number"
          value={value}
          step={step}
          style={{ resize: 'none', width: '100%', padding: '10px' }}
          onChange={(e) => setValue(e.target.value)}
          onBlur={(e: any) => {
            const { value } = e.target;
            if (value > max) {
              e.target.value = max;
            } else if (value < min) {
              e.target.value = min;
            }
            setValue(e.target.value);
          }}
          min={min}
          max={max}
        />
        {label}
      </label>
    </div>
  );
};

export default NumberInput;
