import React, { useState } from 'react';
import { SketchPicker } from 'react-color';

interface IColorPickerProps {
  label?: string;
  color?: string;
  setColor: (color: string) => void;
}

const SketchPickerComponent = SketchPicker as any;

const ColorPicker = ({ label, color, setColor }: IColorPickerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative inline-block">
      {label && <label className="block mb-1">{label}</label>}
      <div
        className="w-10 h-10 rounded cursor-pointer border border-gray-300"
        style={{ backgroundColor: color }}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <div className="absolute right-0 mt-2 z-50 shadow-lg bg-white p-2 border rounded">
          <SketchPickerComponent
            color={color}
            onChangeComplete={(newColor: { hex: string }) =>
              setColor(newColor.hex)
            }
          />
          <button
            className="mt-2 px-2 py-1 bg-gray-200 rounded w-full"
            onClick={() => setIsOpen(false)}
          >
            Close
          </button>
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
