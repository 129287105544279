import React, { useEffect, useState } from 'react';
import { Text } from 'components/Text';
import { Button } from 'components/Button';
import { SizeEnum } from 'utils';

type CodeTextProps = {
  value: string;
};

type CodeBlockProps = {
  children?: React.ReactNode;
};

export const CodeText = ({ value }: CodeTextProps) => {
  return (
    <Text
      value={value}
      type="bold"
      className="border border-gray-300 border-solid bg-gray-100 rounded-sm px-1 ml-1 text-gray-900"
    />
  );
};

export const CodeBlock = ({ children }: CodeBlockProps) => {
  const [hasJustCopied, setHasJustCopied] = useState(false);
  const [text, setText] = useState('Copy code');

  useEffect(() => {
    let timeout: any;

    if (hasJustCopied) {
      setText('Copied!');

      timeout = setTimeout(() => {
        setHasJustCopied(false);
        setText('Copy code');
      }, 2000);
    }

    return () => timeout && clearTimeout(timeout);
  }, [text, hasJustCopied]);

  return (
    <div className="mt-5 mx-3 max-w-2xl w-full block">
      <code className="block border whitespace-pre border-gray-300 border-solid rounded-sm pb-4 px-3 text-gray-900 mb-1 overflow-x-scroll">
        {children}
      </code>

      <Button
        className="mt-4"
        variant="outlined"
        size={SizeEnum.xs}
        onClick={() => {
          navigator.clipboard.writeText(`${children}`);
          setHasJustCopied(true);
        }}
      >
        {text}
      </Button>
    </div>
  );
};
