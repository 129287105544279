import React, { useEffect } from 'react';
import { IForm } from './EmbedClaimPortal';
import { NotificationTypes } from 'utils';

interface IPreviewProps {
  form: IForm;
  showNotification: (type: NotificationTypes, message: string) => void;
}

const Preview = ({ form }: IPreviewProps) => {
  useEffect(() => {
    const script = `
    window.initShipAidPortal('#widget', {
        key: "${form.key}"
    });
    `;

    const preview = document.getElementById('container');

    if (preview) {
      preview.innerHTML = '';

      // Preview Widget Container
      const widget = document.createElement('div');
      widget.id = 'widget';
      widget.style.width = '100%';
      widget.style.height = '100vh';

      const scriptTag = document.createElement('script');
      scriptTag.innerHTML = script;
      preview.appendChild(widget);
      preview.appendChild(scriptTag);
    }
  }, [form]);

  return (
    <div className="flex justify-center items-center" id="container"></div>
  );
};

export default Preview;
