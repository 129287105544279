import React, { useState, useEffect, useCallback } from 'react';
import { IForm } from './EmbedClaimPortal';
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import { generateUniqueId, NotificationTypes } from 'utils';
import { useMutation } from '@apollo/client';
import { INJECT_EMBED_SCRIPT } from 'gql/mutations';
import { useStore } from 'context/store-context';

interface ICodeScriptProps {
  form: IForm;
  showNotification: (type: NotificationTypes, message: string) => void;
}
const CodeScript = ({ form, showNotification }: ICodeScriptProps) => {
  const { storeId } = useStore();
  const [injectableScriptString] = useState(
    `<script src="${process.env.REACT_APP_CLAIM_PORTAL_EMBEDSCRIPT_URL}" async></script>`,
  );
  const [script, setScript] = useState('');

  const isInjected = document.cookie.includes('shipaid_portal_injected=true');

  const [injectEmbedScript, { loading }] = useMutation(INJECT_EMBED_SCRIPT, {
    onCompleted: () => {
      document.cookie = 'shipaid_portal_injected=true';
      showNotification(
        NotificationTypes.success,
        'Script injected successfully',
      );
      setStep(2);
    },
  });

  const [step, setStep] = useState<number>(1);

  const onSetStep = (step: number) => {
    setStep(step);
  };

  useEffect(() => {
    const containerId = generateUniqueId();
    const script = `
      <div style="width: 100%; height: 100vh" id="${containerId}"></div>
      <script type="text/javascript">
        function loadShipAidPortal() {
          let startTime = performance.now();
          const timeout = 10000;
  
          function checkLoaded() {
            if (typeof window.initShipAidPortal === 'function') {
              window.initShipAidPortal('#${containerId}', {
                key: "${form.key}"
              });
            } else if (performance.now() - startTime < timeout) {
              requestAnimationFrame(checkLoaded);
            } else {
              console.error("ShipAid Portal script failed to load within timeout.");
            }
          }
  
          requestAnimationFrame(checkLoaded);
        }
        loadShipAidPortal();
      </script>`;

    // Append script dynamically if needed
    setScript(script);
  }, [form]);

  const onCopy = (content: string) => {
    navigator.clipboard.writeText(content);
    showNotification(NotificationTypes.success, 'Copied successfully');
  };

  const onInjectScript = useCallback(() => {
    injectEmbedScript({
      variables: {
        input: {
          storeId: storeId,
          script: process.env.REACT_APP_CLAIM_PORTAL_EMBEDSCRIPT_URL,
        },
      },
    });
  }, [storeId, injectEmbedScript]);

  return (
    <div className="max-w-6xl mx-auto p-6">
      <div className="max-w-6xl mx-auto p-6">
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <div
              className="relative flex-1 cursor-pointer"
              onClick={onSetStep.bind(null, 1)}
            >
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-indigo-600 text-white font-semibold text-sm z-10">
                  1
                </div>
                <p className="mt-2 text-sm font-medium text-gray-900">
                  Inject Script
                </p>
              </div>
              <div className="absolute top-5 left-1/2 w-full h-0.5 bg-blue-500"></div>
            </div>

            <div
              className="relative flex-1 cursor-pointer"
              onClick={onSetStep.bind(null, 2)}
            >
              <div className="flex flex-col items-center">
                <div className="flex items-center justify-center w-10 h-10 rounded-full bg-indigo-600 text-white font-semibold text-sm z-10">
                  2
                </div>
                <p className="mt-2 text-sm font-medium text-gray-900">
                  Copy Code Block
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {step === 1 && (
        <div>
          <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-100">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">
              Step 1: Inject Embed Script On your Shopify Theme
            </h2>

            <div className="bg-gray-50 rounded-md p-4 border border-dashed border-gray-200 mb-6">
              <h4 className="text-sm font-medium text-gray-700 mb-2 flex items-center">
                Option 1: Automatic Injection
                <span className="ml-2 px-2 py-1 text-xs font-semibold text-white bg-blue-500 rounded-md">
                  Recommended
                </span>
              </h4>

              <p className="text-sm text-gray-600">
                Let us automatically add the script to your Shopify theme. Make
                sure you&lsquo;re logged into your Shopify store before
                proceeding.
              </p>

              <div className="mt-4 flex flex-col sm:flex-row gap-4">
                <button
                  onClick={!isInjected ? onInjectScript : () => {}}
                  disabled={loading}
                  className={`${
                    isInjected
                      ? 'bg-green-600 hover:bg-green-700'
                      : 'bg-blue-600 hover:bg-blue-700'
                  } text-white py-2 px-4 rounded-md font-medium text-sm transition-colors`}
                >
                  {isInjected
                    ? 'Injection is completed'
                    : loading
                    ? 'Please wait...'
                    : 'Inject Automatically'}
                </button>
              </div>

              <p className="text-xs text-gray-500 mt-3">
                This will securely add the script to your themes.
              </p>
            </div>

            <div className="bg-gray-50 rounded-md p-4 border border-dashed border-gray-200 mb-6">
              <h4 className="text-sm font-medium text-gray-700 mb-2">
                Option 2: Manual Injection (Advanced)
              </h4>
              <p className="text-sm text-gray-600">
                Navigate to your Shopify admin panel, then go to Online Store
                &gt; Themes &gt; Actions &gt; Edit code. Find the theme.liquid
                file and add the following script just before the closing
                &lt;/head&gt; tag:
              </p>

              <div className="relative mt-4">
                <div className="bg-gray-800 text-gray-200 rounded-md p-4 font-mono text-sm overflow-x-auto">
                  {injectableScriptString}
                </div>
                <button
                  onClick={onCopy.bind(null, injectableScriptString)}
                  className="absolute top-2 right-2 bg-gray-700 hover:bg-gray-600 text-xs text-white py-1 px-2 rounded transition-colors"
                >
                  Copy
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="flex-1">
          <div className="bg-white rounded-lg shadow-sm p-6 border border-gray-100">
            <div>
              <h2 className="text-lg font-semibold text-gray-900 mb-4">
                Step 2: Copy Script Code
              </h2>

              <div className="bg-gray-50 rounded-md p-4 border border-dashed border-gray-200 mb-6 flex justify-between items-center">
                <div>
                  <h4 className="text-sm font-medium text-gray-700 mb-2">
                    Integration Script
                  </h4>
                  <p className="text-sm text-gray-600">
                    Copy the following code and paste it into your Shopify theme
                    pages where you want the integration to appear:
                  </p>
                  <p className="text-sm my-2 text-gray-600">
                    Navigate to your Shopify admin panel, then go to Online
                    Store &gt; Pages &gt; Add Page (Eg: Claims Portal) &gt; Show
                    HTML &gt; Paste the copied code Block.
                  </p>

                  <div className="text-sm my-2 text-blue-600">
                    <a
                      target="_new"
                      href="http://res.cloudinary.com/media-resource/image/upload/v1740507980/shipaid-dev/t5exgw7jyrcxczpbwd1l.png"
                    >
                      View example screenshot
                    </a>
                  </div>
                </div>
                <div>
                  <button
                    onClick={onCopy.bind(null, script)}
                    className="bg-gray-700 hover:bg-gray-600 text-sm text-white py-1 px-2 rounded transition-colors"
                  >
                    Copy
                  </button>
                </div>
              </div>

              <div className="relative">
                <div className="bg-gray-800 text-gray-200 rounded-md p-4 font-mono text-sm overflow-x-auto">
                  <CodeMirror
                    className="cursor-pointer"
                    onClick={onCopy.bind(null, script)}
                    value={script}
                    editable={false}
                    extensions={[html()]}
                    theme="dark"
                  />
                </div>
                <button className="absolute top-2 right-2 bg-gray-700 hover:bg-gray-600 text-xs text-white py-1 px-2 rounded transition-colors">
                  Copy
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex items-center justify-between my-4">
        <button
          disabled={step === 1}
          onClick={onSetStep.bind(null, 1)}
          className="px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-blue-300 disabled:border-gray-400 disabled:bg-gray-300 rounded-lg shadow-sm hover:bg-gray-100 transition-all"
        >
          ← Prev
        </button>
        <button
          disabled={step === 2}
          onClick={onSetStep.bind(null, 2)}
          className="px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-blue-600 disabled:border-gray-400  disabled:bg-gray-300 rounded-lg shadow-sm hover:bg-blue-600 transition-all"
        >
          Next →
        </button>
      </div>
    </div>
  );
};

export default CodeScript;
