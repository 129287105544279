import React from 'react';

interface IToggleCheckBoxProps {
  label?: string;
  selected: string;
  selectOption: (selected: string) => void;
  options: { label: string; value: string }[];
}

const SelectBox = ({
  label,
  selected,
  selectOption,
  options,
}: IToggleCheckBoxProps) => {
  return (
    <div>
      <label>
        {label}
        <select
          value={selected}
          onChange={(e: any) => selectOption(e.target.value)}
        >
          {options.map((o) => (
            <option value={o.value} key={o.value}>
              {o.label}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};

export default SelectBox;
