import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from 'components/Button';
import { Loader } from 'components/Loader';
import { withNotification } from 'components/Notification';
import { Text } from 'components/Text';
import { useStore } from 'context/store-context';
import { CHANGE_PAYMENT_METHOD } from 'gql/mutations';
import { GET_PAYMENT_METHOD_INFO } from 'gql/queries';
import React, { useEffect, useState } from 'react';
import Cards, { Focused } from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import { NotificationTypes } from 'utils';

const PaymentMethod = ({ showNotification }: any) => {
  const { storeId, storeProperties } = useStore();
  const [billingDetails, setBillingDetails] = useState<any>(null);

  const [focused, setFocussed] = useState<Focused>('number');

  const onClickCard = () => {
    setFocussed((focused) => (focused === 'cvc' ? 'number' : 'cvc'));
  };

  const [getPaymentMethod, { loading: getPaymentMethodLoading }] = useLazyQuery(
    GET_PAYMENT_METHOD_INFO,
    {
      onCompleted: (data) => {
        if (data && data.getStripePaymentMethodDetail) {
          setBillingDetails(data.getStripePaymentMethodDetail);
        } else {
          setBillingDetails(null);
        }
      },
    },
  );

  const [changePaymentMethod] = useMutation(CHANGE_PAYMENT_METHOD, {
    onCompleted: (data) => {
      if (data && data.changeStripePaymentMethod) {
        showNotification(NotificationTypes.success, 'You will be redirected');
        window.open(data.changeStripePaymentMethod);
      } else {
        showNotification(
          NotificationTypes.error,
          'Unknown error occurred, retry again',
        );
      }
    },
  });

  useEffect(() => {
    if (
      storeId &&
      storeProperties &&
      storeProperties.useCustomApp &&
      storeProperties.billing
    ) {
      getPaymentMethod({
        variables: {
          storeId: storeId,
        },
      });
    } else {
      setBillingDetails(null);
    }
  }, [getPaymentMethod, storeId, storeProperties]);

  const onHandlePaymentMethod = () => {
    if (storeId) {
      changePaymentMethod({
        variables: {
          storeId,
        },
      });
    }
  };

  if (getPaymentMethodLoading) {
    return <Loader />;
  }

  return (
    <div className="border rounded-lg p-6 bg-white shadow-sm">
      <h2 className="text-xl font-semibold mb-4">Payment Method</h2>

      {storeProperties?.useCustomApp ? (
        <div>
          <div className="flex justify-between my-5 w-full">
            <div className="flex flex-col justify-between">
              <div>
                <div className="font-bold text-md">Stripe Billing</div>
                {billingDetails ? (
                  <div>
                    <Text
                      className="text-blue-500 font-bold"
                      value={`Name: ${billingDetails.billing_details.name}`}
                    />
                    <Text
                      className="text-gray-400"
                      value={`Email: ${billingDetails.billing_details.email}`}
                    />
                  </div>
                ) : (
                  <div>No Payment Method Added</div>
                )}
              </div>
              <Button
                onClick={onHandlePaymentMethod}
                className="w-fit mt-5 text-center"
              >
                {billingDetails
                  ? 'Change Payment Method'
                  : 'Add Payment Method'}
              </Button>
            </div>
            {billingDetails && (
              <div onClick={onClickCard} className="cursor-pointer">
                <Cards
                  number={`**** **** **** ${billingDetails.card.last4}`}
                  name={billingDetails.billing_details.name}
                  expiry={`${String(billingDetails.card.exp_month).padStart(
                    2,
                    '0',
                  )}/${String(billingDetails.card.exp_year).slice(-2)}`}
                  cvc={''}
                  focused={focused}
                  preview
                  issuer={billingDetails.card.brand}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div className="font-bold text-md py-4">Shopify Billing</div>
        </div>
      )}
    </div>
  );
};

export default withNotification(PaymentMethod);
