/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { LoadingScreen } from 'components/LoadingScreen';
import { useStore } from 'context/store-context';
import { withNotification } from 'components/Notification';

import { Tabs } from 'components/Tabs';
import CustomizeSettings from './CustomizeSettings';
import Preview from './Preview';
import CodeScript from './CodeScript';
import { generateApiKey, NotificationTypes } from 'utils';
import { useMutation } from '@apollo/client';
import { UPDATE_STORE } from 'gql/mutations';

const tabs = [
  { name: 'Customize Portal' },
  { name: 'Preview' },
  { name: 'Generate Code/Script' },
];

export const BasicSetupForm: IForm = {
  key: '',
  template: '1',
  logo: {
    url: 'http://res.cloudinary.com/media-resource/image/upload/v1738345674/shipaid-dev/tfyiyogbc53wp0dllwfv.png',
    size: '250',
    position: 'start',
    enableLogo: true,
  },
  theme: {
    primary: '#2563EB',
    border: '#2563EB',
    background: '#FFFFFF',
    text: '#000000',
    buttonText: '#FFFFFF',
  },
  backgrounds: {
    homePageBanner:
      'http://res.cloudinary.com/media-resource/image/upload/v1738345872/shipaid-dev/mov3594qtj0swrphckzw.jpg',
    enableBanner: true,
    homePageBackgroundColor: '',
    formOverlayOpacity: '1',
  },
  labels: {
    homePageHeader: 'Lost or Damaged Package?',
    homePageDescription:
      "You ordered from your favorite brand online but your package didn't arrive as you had hoped. No worries, things happen! Let's find your order and report it to the brand you purchased from so they can resolve your issue in no time.",
    emailPlaceholder: 'Email or Phone',
    orderNumberPlaceholder: 'Order Number',
    searchButtonText: 'Search',
    newDeliveryIssueTitleLabel: 'New Delivery Issue',
    newDeliveryIssueDescLabel:
      'Please fill out the information below to file an issue. Provide as much detail to assist with quick resolution',
    itemsHeadingLabel: 'Items',
    selectIssueItemsLabel: 'Select issue item(s)',
    selectAtleastOneItemErrorLabel: 'Please select one item',
    causeHeadingLabel: 'Cause',
    selectCauseDescLabel:
      'Choose from one of the causes below and input additional information',
    selectIssueCauseLabel: 'Please select the cause for the issue',
    uploadImagesHeadingLabel: 'Upload Images',
    uploadImagesDescLabel: 'Add images that illustrate the issue',
    uploadImagesMaxLabel:
      'Add up to 5 images that illustrate the issue and for future reference',
    uploadProgressLabel: 'Please wait, upload is in progress',
    uploadAtleastOneImageLabel: 'Please upload at least one image',
    uploadVideosHeadingLabel: 'Upload Videos',
    uploadVideosDescLabel: 'Add videos that illustrate the issue',
    uploadVideosMaxLabel:
      'Add up to 1 videos that illustrate the issue and for future reference',
    uploadAtleastOneVideoLabel: 'Please upload at least one video',
    preferredSolutionHeadingLabel: 'Preferred Resolution',
    preferredSolutionDescLabel:
      'Choose how you prefer for us to resolve this issue',
    preferredSolutionOtherLabel:
      'Because you selected "Other" as the Preferred Resolution, please provide more details',
    preferredSolutionOtherTextAreaLabel: 'Provide Preferred Resolution details',
    notesHeadingLabel: 'Your Notes',
    notesDescLabel: 'Provide any notes that would help support',
    orderNumberLabel: 'Order Number',
    orderDateLabel: 'Order Date',
    orderTotalLabel: 'Order Total',
    emailLabel: 'Email',
    phoneLabel: 'Phone',
    shippedToLabel: 'Shipped To',
    trackingLabel: 'Tracking',
    estDeliveryDateLabel: 'Estimated Delivery Date',
    shippingAddressLabel: 'Shipping Address',
    refundPolicyLabel: 'Refund Policy',
    reviewIssueButtonLabel: 'Review Issue',
    downloadLabel: 'Download',
    issueFiledHeadingLabel: 'Issue Filed',
    claimInfoLabel:
      'Thank you for filing a delivery issue! Your issue number is',
    claimInfoDescLabel: 'You will hear back from',
    claimInfoContentLabel:
      'within the next 2-3 business days with a resolution and/or response.',
    itemsLabel: 'Items',
    summaryLabel: 'Summary',
    issueNotesLabel: 'Issue Notes',
    issueTotalLabel: 'Issue Total',
    issueImagesLabel: 'Issue Images',
    noImagesTextLabel: 'No Images added to this issue',
    videosLabel: 'Videos',
    noVideosTextLabel: 'No Videos added to this issue',
    contactLabel: 'Contact',
    contactDeclarationLabel:
      'If you have any questions or need to send more information about this issue, please write your message below. The information for this issue will be enclosed automatically for reference with your message. You will receive an email with further correspondance.',
    messageSentLabel: 'Message was sent!',
    sendMessageBtnLabel: 'Send Message',
    changeShippingAddressLabel: 'Change Shipping Address',
    addressLabel: 'Address',
    addressPlaceholderLabel: 'Address',
    address2Label: 'Address 2',
    address2PlaceholderLabel: 'Address 2',
    cityLabel: 'City',
    cityPlaceholderLabel: 'City',
    stateLabel: 'State',
    statePlaceholderLabel: 'State',
    zipLabel: 'Zip',
    zipPlaceholderLabel: 'Zip',
    countryLabel: 'Country',
    countryPlaceholderLabel: 'Country',
    changeAddressButtonLabel: 'Change Address',
    reviewIssueHeadingLabel: 'Review Issue',
    reviewIssueDescLabel:
      'Please review the issue below and ensure all the information is correct before submitting',
    imagesHeadingLabel: 'Images',
    editIssueLabel: 'Edit Issue',
    submitIssueButtonLabel: 'Submit Issue',
  },
  containerStyle: {
    borderRadius: '10',
  },
};

export interface IForm {
  key: string;
  template?: '1' | '2' | '3';
  logo?: {
    url?: string;
    size?: string;
    position?: 'start' | 'center' | 'end';
    enableLogo?: boolean;
  };
  theme?: {
    primary?: string;
    border?: string;
    background?: string;
    text?: string;
    buttonText?: string;
  };
  backgrounds?: {
    homePageBanner?: string;
    enableBanner?: boolean;
    homePageBackgroundColor?: string;
    formOverlayOpacity?: string;
  };
  labels?: {
    [key: string]: string;
  };
  containerStyle?: {
    borderRadius?: string;
  };
}

export type KeyType = keyof IForm;

export const EmbedClaimPortal = withNotification(
  ({ showNotification }: any) => {
    const { storeId, storeLoading, storeProperties } = useStore();

    const [updateStoreField] = useMutation(UPDATE_STORE, {});

    const [tab, setTab] = useState('Customize Portal');

    const [form, setForm] = useState<IForm>({
      ...BasicSetupForm,
    });

    useEffect(() => {
      if (storeProperties && storeProperties.embedScriptSettings) {
        setForm((form) => ({
          ...form,
          key: storeProperties?.embedScriptSettings?.key || generateApiKey(),
          ...(storeProperties.embedScriptSettings || {}),
        }));
      }
    }, [storeProperties]);

    const onUpdateFormInStoreDB = useCallback((updates: IForm) => {
      updateStoreField({
        variables: {
          storeId: storeId,
          set: {
            embedScriptSettings: {
              ...updates,
              key: updates.key || generateApiKey(),
            },
          },
        },
      });
    }, []);

    const setFormValues = (
      key: KeyType,
      value: string | Record<string, string | boolean | number>,
    ) => {
      let updates = undefined;
      if (typeof value === 'string') {
        setForm((form) => {
          updates = {
            ...form,
            [key]: value,
          };
          onUpdateFormInStoreDB(updates);
          return updates;
        });
      } else {
        setForm((form) => {
          updates = {
            ...form,
            [key]: {
              ...((form[key] as any) || {}),
              ...value,
            },
          };
          onUpdateFormInStoreDB(updates);
          return updates;
        });
      }
    };

    const onReset = () => {
      setForm(BasicSetupForm);
      showNotification(
        NotificationTypes.success,
        'All customizations are reset',
      );
    };

    useEffect(() => {
      if (!document.getElementById('shipaid-embed-claim-portal-script')) {
        const scriptTag = document.createElement('script');
        scriptTag.id = 'shipaid-embed-claim-portal';
        scriptTag.src = process.env
          .REACT_APP_CLAIM_PORTAL_EMBEDSCRIPT_URL as string;

        document.head.appendChild(scriptTag);
      }
    }, []);

    if (storeLoading && !storeId && !storeProperties) return <LoadingScreen />;
    return (
      <div className="max-w-7xl mx-auto">
        <Tabs
          tabs={tabs}
          selectedTab={tab}
          setSelectedTab={(t: string) => setTab(t)}
        />

        <div>
          {tabs[0].name === tab && (
            <CustomizeSettings
              form={form}
              setForm={setFormValues}
              onReset={onReset}
            />
          )}
          {tabs[1].name === tab && (
            <Preview form={form} showNotification={showNotification} />
          )}
          {tabs[2].name === tab && (
            <CodeScript form={form} showNotification={showNotification} />
          )}
        </div>
      </div>
    );
  },
);

export default EmbedClaimPortal;
