import React from 'react';

interface ITextInputProps {
  label: string;
  value: string;
  setValue: (val: string) => void;
}

const TextInput = ({ label, value, setValue }: ITextInputProps) => {
  return (
    <div className="border rounded-sm w-[300px]">
      <label>
        <textarea
          rows={2}
          value={value}
          placeholder="Please type here..."
          style={{ resize: 'none', width: '100%', padding: '10px' }}
          onChange={(e) => setValue(e.target.value)}
        />
        {label}
      </label>
    </div>
  );
};

export default TextInput;
