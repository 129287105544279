import React from 'react';
import { BasicSetupForm, IForm, KeyType } from './EmbedClaimPortal';
import SectionPanel, { FieldSection } from './Section';
import ImageUploader from './ImageUploader';
import NumberInput from './NumberInput';
import TextInput from './TextInput';
import ColorPicker from './ColorPicker';
import { Button } from 'components/Button';
import SelectBox from './SelectBox';
import { Toggle } from 'components/Toggle';
interface ICustomizeSettingsProps {
  form: IForm;
  setForm: (
    key: KeyType,
    value: string | Record<string, string | boolean | number>,
  ) => void;
  onReset: () => void;
}

const Templates = [
  {
    id: '1',
    url: 'https://res.cloudinary.com/media-resource/image/upload/v1738344070/shipaid-dev/b6exk2iylvk2co2zvaim.png',
  },
  {
    id: '2',
    url: 'http://res.cloudinary.com/media-resource/image/upload/v1738344113/shipaid-dev/dq801wznalhgmtlojdsj.png',
  },
  {
    id: '3',
    url: 'http://res.cloudinary.com/media-resource/image/upload/v1738344140/shipaid-dev/v850wsuinmsszzdb9gom.png',
  },
];

const CustomizeSettings = ({
  form,
  setForm,
  onReset,
}: ICustomizeSettingsProps) => {
  return (
    <div className="my-10">
      <SectionPanel title="Choose Template">
        <div className="flex gap-2 justify-center">
          {Templates.map((t) => (
            <div
              key={t.id}
              className={`border rounded-md ${
                form.template === t.id ? 'border-4 border-blue-400' : ''
              }`}
              style={{
                width: '300px',
                height: '180px',
                background: `url(${t.url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              {form.template !== t.id && (
                <div className="m-2">
                  <Button onClick={() => setForm('template', t.id)}>
                    Select
                  </Button>
                </div>
              )}
            </div>
          ))}
        </div>
      </SectionPanel>
      <SectionPanel title="Logo">
        <FieldSection
          left={<div className="font-normal text-md">Enable</div>}
          right={
            <Toggle
              isEnabled={form.logo?.enableLogo ?? true}
              onChange={(enableLogo: boolean) =>
                setForm('logo', { enableLogo })
              }
            />
          }
        />
        {(form?.logo?.enableLogo ?? true) && (
          <>
            <FieldSection
              left={<div className="font-normal text-md">Image</div>}
              right={
                <ImageUploader
                  image={form.logo?.url || ''}
                  setImage={(url) => setForm('logo', { url })}
                />
              }
            />

            <FieldSection
              left={<div className="font-normal text-md">Size</div>}
              right={
                <NumberInput
                  value={form.logo?.size || '250'}
                  min={50}
                  max={500}
                  setValue={(size: number | string) =>
                    setForm('logo', { size })
                  }
                />
              }
            />

            <FieldSection
              left={<div className="font-normal text-md">Position</div>}
              right={
                <SelectBox
                  options={[
                    { label: 'Left', value: 'start' },
                    { label: 'Center', value: 'center' },
                    { label: 'Right', value: 'end' },
                  ]}
                  selected={form.logo?.position || 'start'}
                  selectOption={(position: string) =>
                    setForm('logo', { position })
                  }
                />
              }
            />
          </>
        )}
      </SectionPanel>

      <SectionPanel title="Theme">
        <div className="font-bold text-md mb-4">Colors</div>
        <FieldSection
          left={<div className="font-normal text-md">Primary</div>}
          right={
            <ColorPicker
              color={form.theme?.primary || ''}
              setColor={(primary: number | string) =>
                setForm('theme', { primary })
              }
            />
          }
        />

        <FieldSection
          left={<div className="font-normal text-md">Background</div>}
          right={
            <ColorPicker
              color={form.theme?.background || ''}
              setColor={(background: number | string) =>
                setForm('theme', { background })
              }
            />
          }
        />

        <FieldSection
          left={<div className="font-normal text-md">Border</div>}
          right={
            <ColorPicker
              color={form.theme?.border || ''}
              setColor={(border: number | string) =>
                setForm('theme', { border })
              }
            />
          }
        />

        <FieldSection
          left={<div className="font-normal text-md">Text</div>}
          right={
            <ColorPicker
              color={form.theme?.text || ''}
              setColor={(text: number | string) => setForm('theme', { text })}
            />
          }
        />

        <FieldSection
          left={<div className="font-normal text-md">Button Text</div>}
          right={
            <ColorPicker
              color={form.theme?.buttonText || ''}
              setColor={(buttonText: number | string) =>
                setForm('theme', { buttonText })
              }
            />
          }
        />
      </SectionPanel>
      <SectionPanel title="Backrounds">
        <FieldSection
          left={<div className="font-bold text-md">Enable HomePage Banner</div>}
          right={
            <Toggle
              isEnabled={form.backgrounds?.enableBanner ?? true}
              onChange={(enableBanner: boolean) =>
                setForm('backgrounds', { enableBanner })
              }
            />
          }
        />

        {form?.backgrounds?.enableBanner ? (
          <FieldSection
            left={<div className="font-bold text-md">Image</div>}
            right={
              <ImageUploader
                image={form.backgrounds?.homePageBanner || ''}
                setImage={(homePageBanner) =>
                  setForm('backgrounds', { homePageBanner })
                }
              />
            }
          />
        ) : (
          <FieldSection
            left={<div className="font-bold text-md">Background Color</div>}
            right={
              <ColorPicker
                color={form.backgrounds?.homePageBackgroundColor || ''}
                setColor={(homePageBackgroundColor: number | string) =>
                  setForm('backgrounds', { homePageBackgroundColor })
                }
              />
            }
          />
        )}

        <FieldSection
          left={
            <div className="font-bold text-md">
              Background Transparency (0 - 100%)
            </div>
          }
          right={
            <NumberInput
              label=""
              min={0}
              step="1"
              max={100}
              value={form.backgrounds?.formOverlayOpacity || ''}
              setValue={(formOverlayOpacity: number | string) =>
                setForm('backgrounds', { formOverlayOpacity })
              }
            />
          }
        />
      </SectionPanel>

      <SectionPanel title="Labels/Translations">
        {Object.entries(BasicSetupForm.labels || {}).map(([key, content]) => (
          <FieldSection
            key={key}
            left={<div className="font-bold text-md">{content}</div>}
            right={
              <TextInput
                label=""
                value={form.labels?.[key] || ''}
                setValue={(val) => setForm('labels', { [key]: val })}
              />
            }
          />
        ))}
      </SectionPanel>

      <SectionPanel title="Container Styles">
        <FieldSection
          left={<div className="font-bold text-md">Border Radius</div>}
          right={
            <NumberInput
              label=""
              value={form.containerStyle?.borderRadius || ''}
              setValue={(borderRadius: number | string) =>
                setForm('containerStyle', { borderRadius })
              }
            />
          }
        />
      </SectionPanel>
      <div className="my-2">
        <Button variant="outlined" onClick={onReset}>
          Reset Customization
        </Button>
      </div>
    </div>
  );
};

export default CustomizeSettings;
